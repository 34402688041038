<template>
  <div>
    <div id="header">
      <div class="d-flex flex-row justify-content-around logo">
        <img
          src="@/assets/skl-logo.png"
          alt="Smiley face"
          height="27"
          width="70"
          class="mb-2"
        />
      </div>
      <div id="header-title" class="text-center title">
        <b>ส่งเอกสาร /</b>
        <br />
        <b>หลักฐานการชำระให้เจ้าหน้าที่</b>
      </div>
    </div>
    <div class="body">
      <div v-if="page == 1 && dataReady">
        <p class="f-14 font-weight-bold">
          ข้อมูลส่วนตัว <font color="red">*</font>
        </p>
        <!-- <b-form-radio-group
          v-model="form.prefix"
          :options="options"
          class="f-14 mb-3"
          :aria-invalid="true"
        ></b-form-radio-group> -->
        <div class="form-component">
          <InputTextBox
            id="input-1"
            v-model="form.name"
            placeholder="กรุณาระบุชื่อ"
            type="text"
            required
            :isError="inputError.name"
          />
          <InputTextBox
            id="input-2"
            v-model="form.telephone"
            placeholder="กรุณาระบุเบอร์โทรศัพท์"
            type="text"
            pattern="[0-9]*"
            inputmode="tel"
            inputChecker="number"
            format="tel"
            :maxlength="10"
            required
            :isError="inputError.telephone"
          />
        </div>
        <div class="form-inline mt-3 mb-4">
          <p class="f-14 font-weight-bold mb-0">
            แนบเอกสาร <font color="red">*</font>
          </p>

          <span class="f-small-gray ml-1"
            >(อัพโหลดได้ไม่เกิน 5 ไฟล์ ขนาดไม่เกิน 10 MB)</span
          >
        </div>

        <InputUploadFile
          format="file"
          v-on:onFileChange="handleChangFile"
          :isMultiple="true"
          @removeFile="removeFile()"
        />
        <!-- v-model="form.files" -->
        <!-- <br /> -->
        <template v-for="(file, index) in form.files">
          <div
            :key="file.name"
            :style="{
              width: '100%',
              display: 'flex',
              'font-size': '15px',
              'justify-content': 'space-between'
            }"
          >
            {{ file.name }}
            <img
              src="@/assets/images/cancel.png"
              height="20"
              class="img-cancel"
              @click="removeFile(index)"
            />
          </div>
        </template>
        <template v-if="inputError.maxFile">
          <div :style="{ width: '100%', 'text-align': 'center' }">
            <font color="red">อัพโหลดได้ไม่เกิน 5 ไฟล์ ขนาดไม่เกิน 10 MB</font>
          </div>
        </template>
        <template
          v-if="
            inputError.prefix ||
              inputError.name ||
              inputError.telephone ||
              inputError.files
          "
        >
          <div :style="{ width: '100%', 'text-align': 'center' }">
            <font color="red">กรุณาตรวจสอบความถูกต้องอีกครั้ง</font>
          </div>
        </template>
        <br />
        <b-form-checkbox
          v-if="consentState.isShow"
          v-model="consentState.isCheck"
          :style="{
            'font-size': '0.625rem',
            'line-height': '1.3',
            'margin-bottom': '10px'
          }"
        >
          ข้าพเจ้ารับทราบว่า ข้าพเจ้าแจ้งความประสงค์สอบถามข้อมูล ส่งคำถามถึง
          บริษัท สยามคูโบต้า ลีสซิ่ง จำกัด ผ่านช่องทางอิเล็กทรอนิกส์
          ข้าพเจ้าได้อ่าน<a
            id="redirectPrivacyPolicy"
            href="https://skl.co.th/privacy_policy"
            class="f-orange"
            ><u>นโยบายคุ้มครองส่วนบุคคล</u></a
          >
          และยอมรับ<a
            id="redirectTerms"
            href="https://www.skl.co.th/terms"
            class="f-orange"
            ><u>ข้อกำหนดและเงื่อนไขการใช้งาน</u></a
          >
          ของบริษัทฯ แล้ว และรับทราบว่าบริษัทฯ
          จะใช้หรือเปิดเผยข้อมูลข้างต้นให้แก่สยามคูโบต้าคอร์ปอเรชั่นและผู้แทนจำหน่ายสยามคูโบต้า
          ในการติดต่อข้าพเจ้าเพื่อจัดการคำขอและข้อสอบถามต่างๆตามที่ข้าพเจ้าแจ้งความประสงค์นี้
        </b-form-checkbox>
        <div class="text-center">
          <b-button
            variant="submit"
            type="button"
            @click="uploadFile()"
            :disabled="
              buttonLoading || (consentState.isShow && !consentState.isCheck)
            "
          >
            <b-spinner small v-if="buttonLoading"></b-spinner>
            <span v-else>อัพโหลด</span>
          </b-button>
        </div>
      </div>
      <div v-else-if="page == 2">
        <PageResult :data="data" />
      </div>
    </div>
    <PolicyModal ref="policyModal" @Accept="submitPolicy" />
  </div>
</template>

<script>
const PageResult = () => import("@/components/panels/PageResult");
const InputTextBox = () => import("@/components/inputs/InputTextBox-New");
const InputUploadFile = () => import("@/components/inputs/InputUploadFile");
const PolicyModal = () => import("@/components/Policy");

export default {
  components: {
    PageResult,
    InputTextBox,
    InputUploadFile,
    PolicyModal
  },
  data() {
    return {
      lineProfile: {},
      checked: false,
      dataReady: false,
      buttonLoading: false,
      page: 1,
      data: {
        status: "success",
        msg: "ยกเลิกการลงทะเบีบยนใช้บริการ SKL Connect สำเร็จ"
        // status: 'failed',
        // msg: 'ยกเลิกการลงทะเบียนใช้บริการ SKL Connect ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง'
      },
      options: [
        { value: "นาย", text: "นาย" },
        { value: "นาง", text: "นาง" },
        { value: "นางสาว", text: "นางสาว" }
      ],
      form: {
        prefix: "",
        name: "",
        telephone: "",
        files: []
      },
      inputError: {
        prefix: false,
        name: false,
        telephone: false,
        files: false,
        maxFile: false
      },
      customerData: {},
      consentState: {
        isShow: true,
        isCheck: false
      }
    };
  },
  beforeCreate() {
    this.$liff
      .init({ liffId: this.$liff_ID_Document })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.getData();
              this.checkTerm(profile.userId);
              // this.checkConsent();
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
            this.getData();
            this.checkTerm(profile.userId);
            // this.checkConsent();
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  },
  mounted() {},
  methods: {
    checkTerm(lineId) {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/checkterms/${lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (!result.detail) {
              // let routeData = this.$router.resolve({
              //   name: "policy",
              //   query: { lineid: lineId }
              // });
              // window.open(routeData.href, "_blank");
              this.$router.push({
                path: "/policy",
                query: {
                  lineid: lineId,
                  term: true
                }
              });
            }
          } else {
            // let routeData = this.$router.resolve({
            //   name: "policy",
            //   query: { lineid: lineId }
            // });
            // window.open(routeData.href, "_blank");
            this.$router.push({
              path: "/policy",
              query: {
                lineid: lineId,
                term: true
              }
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    createConsentLinkEvent() {
      if (this.$liff.isInClient()) {
        var loop = setInterval(() => {
          if (
            document.getElementById("redirectPrivacyPolicy") &&
            document.getElementById("redirectTerms")
          ) {
            var reP = document.getElementById("redirectPrivacyPolicy");
            reP.onclick = () => {
              this.$liff.openWindow({
                url: "https://skl.co.th/privacy_policy",
                external: false
              });
              return false;
            };
            var reT = document.getElementById("redirectTerms");
            reT.onclick = () => {
              this.$liff.openWindow({
                url: "https://www.skl.co.th/terms",
                external: false
              });
              return false;
            };
            clearInterval(loop);
          }
        }, 300);
      }
    },
    checkConsent() {
      this.buttonLoading = true;
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/consent/${this.lineProfile.userId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            // this.$refs.policyModal.content = result.detail.termsAndConditions;
            if (result.detail.consent) {
              ////
              // this.uploadFile();
              ////
              this.buttonLoading = false;
            } else {
              // this.$bvModal.show("modal-policy");
              // this.buttonLoading = false;
              this.consentState.isShow = true;
              setTimeout(() => {
                this.createConsentLinkEvent();
              }, 0);
              this.buttonLoading = false;
            }
          } else {
            this.$bvModal.show("modal-tryagain");
            this.buttonLoading = false;
          }
        })
        .catch(error => {
          console.log("error", error);
          this.$bvModal.show("modal-tryagain");
          this.buttonLoading = false;
        });
    },
    submitPolicy() {
      // this.buttonLoading = true;
      var requestOptions = {
        method: "POST",
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/consent/${this.lineProfile.userId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          this.$refs.policyModal.buttonLoading = false;
          this.$bvModal.hide("modal-policy");
          if (result.result == 1) {
            ////
            // this.uploadFile();
            ////
          } else {
            this.$bvModal.show("modal-tryagain");
            // this.buttonLoading = false;
          }
        })
        .catch(error => {
          console.log("error", error);
          // this.$bvModal.show("modal-tryagain");
          // this.$bvModal.hide("modal-policy");
          // this.buttonLoading = false;
        });
    },
    getData() {
      var myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/uploadDocument/${this.lineProfile.userId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          console.warn(result);
          if (result.result == 1) {
            this.options = result.detail.prefixName.map(a => {
              return { value: a.name, text: a.name };
            });
            this.form.name = result.detail.customer.customerName;
            this.form.telephone = result.detail.customer.mobileNo;
            // this.form.prefix = result.detail.customer.prefixName;
            this.customerData = JSON.parse(
              JSON.stringify(result.detail.customer)
            );
            console.warn("form", this.form);
          }
          this.dataReady = true;
        })
        .catch(error => {
          console.error("error", error);
        });
      // objArray.map(a => a.foo);
    },
    handleChangFile(files) {
      this.inputError.maxFile = false;
      var tempFiles = [...this.form.files, ...files];
      console.warn("handleChangFile", files, tempFiles.length);
      if (tempFiles.length > 5) {
        console.warn("file length over 5");
        this.inputError.maxFile = true;
        return;
      }
      var sumSize = 0;
      for (let index = 0; index < tempFiles.length; index++) {
        sumSize += tempFiles[index].size;
      }
      console.warn(sumSize, sumSize / 1024 / 1024);
      if (sumSize / 1024 / 1024 > 10) {
        console.warn("sum size over 10 MB");
        this.inputError.maxFile = true;
        return;
      }
      this.form.files = [...tempFiles];
      console.warn("this.form.files", this.form.files);
    },
    async uploadFile() {
      // if (this.consentState.isShow && this.consentState.isCheck) {
      //   this.submitPolicy();
      // }
      this.buttonLoading = true;
      this.$isLoadingAlpha = true;
      console.warn("uploading....", this.form.files, this.form);
      var files = this.form.files;
      // if (this.form.prefix == "" || this.form.prefix == null) {
      //   this.inputError.prefix = true;
      //   return;
      // }
      if (this.form.name == "" || this.form.name == null) {
        this.inputError.name = true;
        this.buttonLoading = false;
        this.$isLoadingAlpha = false;
        return;
      }
      if (
        this.form.telephone == "" ||
        this.form.telephone.length != 10 ||
        this.form.telephone == null
      ) {
        this.inputError.telephone = true;
        this.buttonLoading = false;
        this.$isLoadingAlpha = false;
        return;
      }
      if (this.form.files.length == 0 || this.form.files == null) {
        this.inputError.files = true;
        this.buttonLoading = false;
        this.$isLoadingAlpha = false;
        return;
      }
      var data = {
        customer: this.customerData,
        attachFile: []
      };
      data.customer["customerName"] = this.form.name;
      data.customer["mobileNo"] = this.form.telephone;
      // data.customer["prefixName"] = this.form.prefix;
      for (let index = 0; index < files.length; index++) {
        await this.toBase64(files[index]).then(base64 => {
          data.attachFile.push(base64);
        });
      }

      console.warn(data);
      var myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(data);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/uploadDocument`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.warn("result", result);
          this.$isLoadingAlpha = false;
          this.buttonLoading = false;
          if (result.result == 1) {
            this.data.status = "success";
            this.data.msg =
              "สำเร็จ เราได้รับข้อมูลของท่านเรียบร้อยแล้ว\nขอบคุณค่ะ";
            this.page = 2;
          } else {
            this.data.status = "failed";
            this.data.msg =
              "ไม่สำเร็จ\nกรุณาตรวจสอบข้อมูลของท่าน\nและลองใหม่อีกครั้งในภายหลัง";
            this.page = 2;
          }
        })
        .catch(error => {
          console.warn(error);
          this.$isLoadingAlpha = false;
          this.buttonLoading = false;
          this.data.status = "failed";
          this.data.msg =
            "ไม่สำเร็จ\nกรุณาตรวจสอบข้อมูลของท่าน\nและลองใหม่อีกครั้งในภายหลัง";
          this.page = 2;
        });
    },
    removeFile(index) {
      console.warn("removeFile", index);
      if (index == undefined) {
        this.form.files = [];
        return;
      }
      this.form.files.splice(index, 1);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
  }
};
</script>

<style scoped>
.form-component .form-group {
  margin-bottom: 5px !important;
}
.body-h {
  height: calc(100vh - 150px) !important;
}
</style>
