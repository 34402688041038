import { render, staticRenderFns } from "./SendDocument.vue?vue&type=template&id=7ea62821&scoped=true"
import script from "./SendDocument.vue?vue&type=script&lang=js"
export * from "./SendDocument.vue?vue&type=script&lang=js"
import style0 from "./SendDocument.vue?vue&type=style&index=0&id=7ea62821&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ea62821",
  null
  
)

export default component.exports